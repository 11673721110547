
import {defineComponent} from "vue";

export default defineComponent({
    props: {
        amount: Number,
        activeIndex: Number
    },
    methods: {
        getBulletState(index: number) {
            const activeIndex = (this.activeIndex || 0) + 1;
            return index < activeIndex ? "passed" : index === activeIndex ? "active" : "";
        }
    }
});
