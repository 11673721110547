import {defineComponent} from "vue";
import {ITranslation} from "@/interfaces/IConfig";
import {getLanguage} from "@/mixins/StateMixin";
import ConfigModule from "@/modules/ConfigModule";

//Get static translation data
const translationData = ConfigModule.get().translations;


//Translation mixin
export const translationMixin = defineComponent({
    methods: {
        getGlobalTranslation(key: string): string {
            return getTranslationFromObject(translationData[key]) || key;
        },

        getTranslatedCopy<T>(copy: any): T {
            const output: any = {};
            for(const key of Object.keys(copy)) {
                output[key] = this.getTranslation(copy[key]);
            }
            return output;
        },

        getTranslation(input?: ITranslation | string): string {
            if (typeof input === "string") {
                if (input.startsWith("##")) {
                    const parts = input.split("##");
                    if (parts.length >= 2) {
                        return getTranslationFromObject(translationData[parts[1]]);
                    }
                }
                return input;
            } else if (typeof input === "object") {
                return getTranslationFromObject(input);
            } else {
                return "";
            }
        }
    }
});


//Helper functions
function getTranslationFromObject(input?: ITranslation): string {
    const currentLanguage = getLanguage();

    //Extract correct item
    if (input) {
        return currentLanguage in input ? (input as any)[currentLanguage] : input.en;
    } else {
        return "";
    }
}