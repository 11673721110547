<!-- Template -->
<template>
    <div class="loader-container">
        <div class="loader-item"></div>
        <div class="loader-item"></div>
        <div class="loader-item"></div>
        <div class="loader-item"></div>
        <div class="loader-item"></div>
        <div class="loader-item"></div>
        <div class="loader-item"></div>
        <div class="loader-item"></div>
        <div class="loader-item"></div>
        <div class="loader-item"></div>
        <div class="loader-item"></div>
        <div class="loader-item"></div>
    </div>
</template>

<!-- Style -->
<style scoped lang="scss" src="./FancyLoader.scss" />