
import CustomFooter from "@/components/CustomFooter.vue";
import CustomHeader from "@/components/CustomHeader.vue";
import FancyLoader from "@/components/loaders/FancyLoader.vue";
import {IUser} from "@/interfaces/api/IUser";
import {ITranslation} from "@/interfaces/IConfig";
import {IPageCopy} from "@/interfaces/IPage";
import apiModule from "@/modules/APIModule";
import {LoginType, PageName} from "@/utils/Constants";
import Helper from "@/utils/Helper";
import {defineComponent, PropType} from "vue";
import ConfigModule from "@/modules/ConfigModule";
import {translationMixin} from "@/mixins/TranslationMixin";
import {setSaveData} from "@/mixins/StateMixin";
import NormalButton from "@/components/buttons/NormalButton.vue";

//Interface
interface IRegisterVerifyPageCopy extends IPageCopy {
    titleVerifying: ITranslation | string,
    descriptionVerifying: ITranslation | string
}

//Component
export default defineComponent({
    mixins: [translationMixin],
    components: {
        NormalButton,
        FancyLoader,
        CustomFooter,
        CustomHeader
    },
    props: {
        routeData: {type: Object as PropType<IUser>, required: true}
    },
    data() {
        const data = ConfigModule.getPageByName(PageName.REGISTER_VERIFY);
        const requireVerify = ConfigModule.get().settings.emailVerification;

        return {
            PageName,
            translatedCopy: this.getTranslatedCopy<IRegisterVerifyPageCopy>(data.copy),
            isVerified: !requireVerify
        }
    },
    methods: {
        async logIn() {
            const response = await apiModule.loginUser(LoginType.EMAIL, this.routeData);
            if(response["saveData"]) {
                await setSaveData(response["saveData"]);
            }

            //Go to walkthrough
            await this.$router.replace(ConfigModule.getPageLink(PageName.WALKTHROUGH));
        }
    },
    async mounted() {
        while(!this.isVerified) {
            await Helper.sleep(3000);

            //Reload user, to check for verification updates
            await apiModule.getUser()?.reload();

            //Get token & check flag
            this.isVerified = apiModule.getUser()?.emailVerified || false;
        }
    }
});
