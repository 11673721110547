import {IUser} from "@/interfaces/api/IUser";
import {ITagTracker} from "@/interfaces/ITagTracker";
import {getLanguage} from "@/mixins/StateMixin";

/**
 * Helper class with various utility methods
 */
export default class Helper {

    /**
     * A promise-based delay, handy for things like polling
     * @param durationMs the amount of time to wait in milliseconds
     */
    static sleep(durationMs: number): Promise<void> {
        return new Promise(resolve => setTimeout(resolve, durationMs));
    }

    /**
     * WebView URL wrapper, mainly used for McDonald's
     * @param url url to wrap
     * @returns string Webview URL of provided URL
     */
    static getWebViewUrl(url: string): string {
        return `gmalite://gmalite-smartweb?weburl=${url}`;
    }

    /**
     * Get a translated link to the terms PDF
     * @returns string URL to PDF for current language
     */
    static getTermsUrl(): string {
        return `${this.getBaseUrl()}/data/terms_${getLanguage()}.pdf`;
    }

    /**
     * Get the base URL of the website (protocol + domain & port)
     * @returns string the base URL
     */
    static getBaseUrl(): string {
        return `${window.location.protocol}//${window.location.host}`;
    }

    /**
     * Shuffles an array, note that the provided array is modified directly!
     * @param data the array to shuffle
     * @returns data even though it's the original array, it returns it anyway for convenience
     */
    static shuffleArray(data: any[]) {
        for (let i = data.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [data[i], data[j]] = [data[j], data[i]];
        }
        return data;
    }

    /**
     * Get a typed instance of GTM (will be null if GTM is not imported)
     * @returns ITagTracker GTM tracker instance
     */
    static getTracker(): ITagTracker | null {
        return "dataLayer" in window ? (window as any)["dataLayer"] : null;
    }

    /**
     * Get ISO formatted time, mainly for API usage
     * @returns string ISO formatted string of current time
     */
    static getAPIDate(): string {
        const date = new Date();
        return date.toISOString();
    }

    /**
     * Helper to check if we want to enforce the flow on refresh
     * @returns boolean true when flow is enforced, otherwise false
     */
    static isFlowForced(): boolean {
        return process.env.VUE_APP_FORCE_FLOW === "true";
    }

    /**
     * Helper to check if we're running in a local environment
     * @returns boolean true when running locally, otherwise false
     */
    static isLocal(): boolean {
        return process.env.VUE_APP_ENV === "local";
    }

    /**
     * Form data mapper, used for login - converts form output to IUser interface structure for API usage
     * @returns IUser the resulting IUser object
     */
    static mapDataToUser(formData: any, language: string): IUser {
        const userKeys = ["firstName", "lastName", "email", "terms"];
        const skipKeys = ["password"];

        //Set base for output
        const output: any = {
            language: language
        };

        //Map data
        for(const key of Object.keys(formData)) {
            if(skipKeys.includes(key)) {
                continue;
            }

            //Process keys
            if(userKeys.includes(key)) {
                output[key] = formData[key];
            }
            else {
                if(!("extraData" in output)) {
                    output["extraData"] = {};
                }
                output["extraData"][key] = formData[key];
            }
        }

        //Return casted variant
        return output;
    }
}