import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_base_button = _resolveComponent("base-button")!

  return (_openBlock(), _createBlock(_component_base_button, {
    "full-width": !_ctx.image,
    "play-sound": ""
  }, {
    default: _withCtx(() => [
      (_ctx.external)
        ? (_openBlock(), _createBlock("a", {
            key: 0,
            href: _ctx.to,
            target: "_blank",
            rel: "noopener noreferrer"
          }, [
            (_ctx.image)
              ? (_openBlock(), _createBlock("img", {
                  key: 0,
                  class: "button-image",
                  src: _ctx.imageSrc,
                  alt: "button image"
                }, null, 8, ["src"]))
              : (_openBlock(), _createBlock("span", {
                  key: 1,
                  class: ["button-default", _ctx.isDark]
                }, [
                  _renderSlot(_ctx.$slots, "default")
                ], 2))
          ], 8, ["href"]))
        : (_openBlock(), _createBlock(_component_router_link, {
            key: 1,
            class: "button-content",
            to: _ctx.to,
            replace: ""
          }, {
            default: _withCtx(() => [
              (_ctx.image)
                ? (_openBlock(), _createBlock("img", {
                    key: 0,
                    class: "button-image",
                    src: _ctx.imageSrc,
                    alt: "button image"
                  }, null, 8, ["src"]))
                : (_openBlock(), _createBlock("span", {
                    key: 1,
                    class: ["button-default", _ctx.isDark]
                  }, [
                    _renderSlot(_ctx.$slots, "default")
                  ], 2))
            ]),
            _: 3
          }, 8, ["to"]))
    ]),
    _: 3
  }, 8, ["full-width"]))
}