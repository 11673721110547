// @ts-ignore
import Boot from "./states/boot";
// @ts-ignore
import Background from "./states/background";
// @ts-ignore
import Load from "./states/load";
// @ts-ignore
import Main from "./states/main";
// @ts-ignore
import Scaling from "./configs/scaling.js";

import Phaser from "phaser";

export default class Game {

    private instance: Phaser.Game;
    private loaded: boolean = false;

    constructor(parentWidth: number, parentHeight: number)
    {
        // Adjust game width/height if resolution ratio is different from base //
        Scaling.updateResolutionRatio(parentWidth, parentHeight);

        //Construct config
        const config: Phaser.Types.Core.GameConfig = {
            type: Phaser.AUTO,
            width: Scaling.GAME_WIDTH,
            height: Scaling.GAME_HEIGHT,
            scale: {
                parent: "game",
                zoom: 1 / Scaling.DPR,
                width: Scaling.GAME_WIDTH * Scaling.DPR,
                height: Scaling.GAME_HEIGHT * Scaling.DPR,
                mode: Phaser.Scale.FIT,
                autoCenter: Phaser.Scale.CENTER_BOTH
            },
            physics: {
                default: "arcade",
                arcade: {
                    debug: false
                }
            },
            fps: {
                target: 60,
                forceSetTimeOut: false
            },
            render: {
                antialias: true,
                roundPixels: true,
                pixelArt: false
            },
            scene: [
                Boot,
                Background,
                Load,
                Main
            ]
        };

        //Create and run game
        this.instance = new Phaser.Game(config);
    }

    //Events
    setEventListener(name: string, listener?: Function) {
        this.removeEventListener(name);

        //Only set, if actually provided
        if (listener) {
            this.instance.events.on(name, listener);
        }
    }

    removeEventListener(name: string) {
        this.instance.events.off(name);
    }

    //Start/stop scene by key
    startScene(key: string) {
        const scene = this.instance.scene.getScene(key);
        if (scene) {
            scene.scene.start();
        }
    }

    stopScene(key: string) {
        const scene = this.instance.scene.getScene(key);
        if (scene) {
            scene.scene.stop();
        }
    }

    //Load helpers
    beginLoad() {
        if (!this.loaded) {
            this.startScene("load");
            this.loaded = true;
        }
    }

    endLoad() {
        if (this.loaded) {
            this.stopScene("load");
        }
    }

    //Setters
    setSaveData(data: any) {
        this.instance.registry.set("saveData", data);
    }

    setBackgroundColor(color: number) {
        const scene = this.instance.scene.getScene("background") as any;
        if (scene) {
            scene.setColor(color);
        }
    }

    //Used to destroy phaser instance
    destroy() {
        this.instance.destroy(true);
    }
}