import {DialogName, PageName} from "@/utils/Constants";
import {IPage} from "@/interfaces/IPage";
import {IDialog} from "@/interfaces/IDialog";
import configRaw from "@/assets/config.json";
import {IConfig} from "@/interfaces/IConfig";

//Map JSON to type
const configData = (configRaw as any) as IConfig;

/**
 * Module used for config related things
 */
export default class ConfigModule {

    /**
     * Get page data from config by page name
     * @param name a PageName enum value, for the requested page (must exist in config)
     */
    static getPageByName(name: PageName): IPage {
        const page = configData.pages.find(item => item.name === name);
        if(!page) {
            throw new Error("requested page doesn't exist!");
        }
        return page;
    }

    /**
     * Get dialog data from config by dialog name
     * @param name a DialogName enum value, for the requested dialog (must exist in config)
     */
    static getDialogByName(name: DialogName): IDialog {
        const dialog = configData.dialogs[name];
        if(!dialog) {
            throw new Error("requested dialog doesn't exist!");
        }
        return dialog;
    }

    /**
     * Convert a page name into a router link
     * @param pageName the page name enum to be converted to a link
     * @returns string the router link
     */
    static getPageLink(pageName: PageName): string {
        return `/${pageName}`;
    }

    /**
     * Get complete config data, currently only used for settings
     * @returns IConfig the config object
     */
    static get(): IConfig {
        return configData;
    }
}