import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_normal_button = _resolveComponent("normal-button")!
  const _component_base_dialog = _resolveComponent("base-dialog")!

  return (_openBlock(), _createBlock(_component_base_dialog, {
    onDeny: _cache[2] || (_cache[2] = ($event: any) => (_ctx.confirmMode = false)),
    "dialog-data": _ctx.sharedData,
    "show-confirmation": _ctx.confirmMode,
    copy: _ctx.copy,
    image: _ctx.image,
    "confirm-image": _ctx.confirmImage,
    callback: _ctx.callback
  }, {
    buttons: _withCtx(() => [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.buttons, (item) => {
        return (_openBlock(), _createBlock(_component_normal_button, {
          class: "dialog-dynamic-button",
          onClick: ($event: any) => (_ctx.selectOption(item.data))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.getTranslation(item.text)), 1)
          ]),
          _: 2
        }, 1032, ["onClick"]))
      }), 256)),
      _createVNode(_component_normal_button, {
        class: "dialog-dynamic-button",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.callback?.(_ctx.DialogAction.CLOSE))),
        alternate: ""
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.getGlobalTranslation("cancel")), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["dialog-data", "show-confirmation", "copy", "image", "confirm-image", "callback"]))
}