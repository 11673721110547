import {DateTime} from "luxon";
import {getSaveData, setSaveData} from "@/mixins/StateMixin";

//TODO: Drop luxon dependency
//TODO: Incorporate in remote state (done, but needs testing)

//Internal interface
interface IHighScore {
    dailyDate: string,
    daily: number,
    overall: number
}

/**
 * Module used for keeping track of daily high scores, per level
 * Note that this module is not completed yet, misses syncing of this information to server (so may not work properly on iOS devices)
 */
class HighscoreModule {

    private readonly scoreMap: Map<number, IHighScore> = new Map();

    /**
     * Constructor, loads stored data if any
     */
    constructor() {
        const saveData = getSaveData();
        if (saveData.game.highscores) {
            this.scoreMap = new Map(saveData.game.highscores);
        }
    }

    /**
     * Store a score - will determine whether it needs to overwrite the previous one
     * @param level the played level
     * @param score the score of the played level
     */
    store(level: number, score: number) {
        let highest = score;
        let highestDaily = score;

        //Compare values, if existing
        const stored = this.scoreMap.get(level);
        if (stored) {
            const currentHighest = this.getHighest(level);
            const currentHighestDaily = this.getHighestDaily(level);

            //Set highest score
            if (currentHighest > highest) {
                highest = currentHighest;
            }

            //Set highest daily
            if (currentHighestDaily > highestDaily) {
                highestDaily = currentHighestDaily;
            }
        }

        //Update entry
        this.scoreMap.set(level, {
            dailyDate: DateTime.local().set({hour: 0, minute: 0, second: 0, millisecond: 0}).toISO(),
            daily: highestDaily,
            overall: highest
        });

        //Write
        this.save();
    }


    /**
     * Get the current highest score for provided level
     * @param level the level you want the highest score from
     */
    getHighest(level: number) {
        const stored = this.scoreMap.get(level);
        return stored ? stored.overall : 0;
    }

    /**
     * Get the current highest daily score for provided level
     * @param level the level you want the highest daily score from
     */
    getHighestDaily(level: number) {
        const stored = this.scoreMap.get(level);
        if (!stored) {
            return 0;
        }

        //If interval is shorter than 1 day, give back current score
        return HighscoreModule.getIntervalDays(stored.dailyDate) === 0 ? stored.daily : 0;
    }

    /**
     * Internal call used for saving the current data locally
     */
    private save() {
        const saveData = getSaveData();
        saveData.game.highscores = Array.from(this.scoreMap);
        setSaveData(saveData);
    }

    /**
     * Internal helper for calculating amount of days between now and provided date
     * @param date an ISO-formatted date string
     * @returns number the amount of days passed
     */
    private static getIntervalDays(date: string) {
        const storedDate = DateTime.fromISO(date);
        return Math.floor(Math.abs(storedDate.diffNow("days").days));
    }
}

const highscoreModule = new HighscoreModule();
export default highscoreModule;