import Phaser from "phaser";
import Scaling from "../configs/scaling";
import Constants from "../configs/constants";

export default class Load extends Phaser.Scene {

    constructor() {
        super({
            key: "load"
        });
    }

    preload() {
        this.logo = this.add.image(this.cameras.main.width / 2, (this.cameras.main.height / 2) - (60 * Scaling.DPR), "logo");

        //Get bounds, only once as it is an expensive call
        const logoBounds = this.logo.getBounds();

        //Setup loader
        const loaderWidth = 300 * Scaling.DPR;
        const loaderBase = this.add.graphics({
            x: logoBounds.centerX,
            y: logoBounds.bottom + (100 * Scaling.DPR)
        });
        loaderBase.fillStyle(0x5D2C1B, 0.5);
        loaderBase.fillRect(-(loaderWidth) / 2, 0, loaderWidth, 12 * Scaling.DPR);

        const loaderLoaded = this.add.graphics({
            x: logoBounds.centerX - ((loaderWidth) / 2),
            y: logoBounds.bottom + (100 * Scaling.DPR)
        });
        loaderLoaded.fillStyle(0x5D2C1B, 1);
        loaderLoaded.fillRect(0, 0, 0, 12 * Scaling.DPR);

        //Loading event
        this.load.on("progress", value => {
            loaderLoaded.clear();
            loaderLoaded.fillStyle(0x5D2C1B, 1);
            loaderLoaded.fillRect(0, 0, (loaderWidth * value), 12 * Scaling.DPR);
        });

        //Completion event
        this.load.on("complete", () => {

            //Fake delay, to make it seem like it's loading
            this.time.addEvent({
                delay: 1500,
                callback: () => {

                    //Remove loader
                    loaderBase.destroy();
                    loaderLoaded.destroy();

                    //Draw continue text
                    const proceedText = this.add.text(0, logoBounds.bottom + 100 * Scaling.DPR, "Tap to continue", {
                        fixedWidth: this.scene.systems.canvas.width,
                        align: "center",
                        fontFamily: Constants.FONT_BOLD_ITALIC,
                        fontSize: 32 * Scaling.DPR,
                        color: 0x5D2C1B
                    });

                    //Add simple animation
                    this.tweens.add({
                        targets: proceedText,
                        yoyo: true,
                        repeat: -1,
                        duration: 500,
                        alpha: {
                            to: 0.5,
                            from: 1
                        },
                        ease: "Quad"
                    });

                    //Set event for proceeding
                    this.input.on("pointerup", () => {
                        this.input.off("pointerup");

                        //Play click
                        // this.sound.add("button-confirm").play();

                        //Trigger music on background - must be done via here, because otherwise it won't count as user interaction
                        const backgroundScene = this.scene.get("background");
                        if(backgroundScene) {
                            backgroundScene.playMusic();
                        }

                        //Send event
                        this.game.events.emit("loaded");
                    });
                }
            });
        });

        //Load images
        this.load.image("background_image", Scaling.ImagePath("images/game/playfield", "png"));
        this.load.image('logo_text', Scaling.ImagePath('images/game/logo_chouffe', 'png'));
        this.load.image('timer_background', Scaling.ImagePath('images/game/paper', 'png'));
        this.load.image('scribble', Scaling.ImagePath('images/game/scribble', 'png'));

        //Load sounds
        this.load.audio("background-music", ["sounds/background.mp3", "sounds/background.ogg"]);
        this.load.audio("button-confirm", ["sounds/button.mp3"]);
    }
}


