import {defineComponent} from "vue";
import {DialogName, PageName} from "@/utils/Constants";
import {ISaveData} from "@/interfaces/ISaveData";
import apiModule from "@/modules/APIModule";

//State variable
let saveData = getSaveData();
let language = getLanguage();


//State mixin
export const stateMixin = defineComponent({
    methods: {
        setElementShown(name: PageName | DialogName | string, omitServerSync: boolean = false): Promise<void> {
            const key = `shown_${name}`;
            saveData.ui[key] = true;

            //Sync
            return syncState(omitServerSync);
        },

        getElementShown(name: PageName | DialogName | string): boolean {
            const key = `shown_${name}`;
            return key in saveData.ui ? saveData.ui[key] : false;
        }
    }
});


//Internal save state function
function syncState(omitServerSync: boolean = false): Promise<void> {
    localStorage.setItem("saveData", JSON.stringify(saveData));

    //Sync with server
    if(!omitServerSync) {
        return apiModule.updateUser({language: language, saveData: saveData}).catch((e) => console.error("failed to sync state:", e));
    }
    return Promise.resolve();
}


//Setters
export function setLanguage(newLanguage: string, omitServerSync: boolean = false): Promise<void> {
    language = newLanguage;
    return syncState(omitServerSync);
}

export function setSaveData(newSaveData: ISaveData, omitServerSync: boolean = false): Promise<void> {
    saveData = newSaveData;
    return syncState(omitServerSync);
}


//Getters
export function getLanguage(): string {
    if(language) {
        return language;
    }

    //Get from local storage, if set
    const localLanguage = localStorage.getItem("language");
    if(localLanguage) {
        return localLanguage;
    }

    //Return default
    return process.env.VUE_APP_DEFAULT_LANGUAGE || "en";
}

export function getSaveData(): ISaveData {
    if(saveData) {
        return saveData;
    }

    //Parse existing state, if any
    const data = localStorage.getItem("saveData");
    if(data && data !== "null") {
        return JSON.parse(data);
    }

    //Return base state
    return {
        game: {},
        ui: {}
    }
}