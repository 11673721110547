
import BaseDialog, {sharedDialogMixin} from "@/components/dialogs/base/BaseDialog.vue";
import {IPrize} from "@/interfaces/api/IPrize";
import apiModule from "@/modules/APIModule";
import {DialogAction, DialogName} from "@/utils/Constants";
import {defineComponent} from "vue";
import {translationMixin} from "@/mixins/TranslationMixin";
import ConfigModule from "@/modules/ConfigModule";
import NormalButton from "@/components/buttons/NormalButton.vue";

export default defineComponent({
    mixins: [translationMixin, sharedDialogMixin],
    components: {
        NormalButton,
        BaseDialog
    },
    data() {
        const data = ConfigModule.getDialogByName(DialogName.CHECK_PRIZE);
        return {
            copy: data.copy,
            image: data.image,
            forceHide: true,
            prizes: [] as IPrize[]
        }
    },
    methods: {
        callbackInternal(action: DialogAction) {
            if(action === DialogAction.CONFIRM) {
                const apiPromises = this.prizes.map(item => apiModule.confirmPrize(item.id));
                Promise.all(apiPromises).catch(e => console.error(e));
            }
            this.forceHide = true;
        }
    },
    mounted() {
        apiModule.getPersonalPrizeList().then(result => {
            if(result.length) {
                this.prizes = result;
                this.forceHide = false;
            }
        });
    }
});
