
import CustomFooter from "@/components/CustomFooter.vue";
import CustomHeader from "@/components/CustomHeader.vue";
import CustomForm from "@/components/form/CustomForm.vue";
import {IFormPage, IPageCopy} from "@/interfaces/IPage";
import apiModule from "@/modules/APIModule";
import {LoginType, PageName} from "@/utils/Constants";
import Helper from "@/utils/Helper";
import {defineComponent} from "vue";
import {translationMixin} from "@/mixins/TranslationMixin";
import ConfigModule from "@/modules/ConfigModule";
import {getLanguage} from "@/mixins/StateMixin";

export default defineComponent({
    mixins: [translationMixin],
    components: {
        CustomForm,
        CustomFooter,
        CustomHeader
    },
    data() {
        const data = ConfigModule.getPageByName(PageName.REGISTER) as IFormPage;

        return {
            PageName,
            formContent: data.formContent,
            translatedCopy: this.getTranslatedCopy<IPageCopy>(data.copy)
        }
    },
    methods: {
        async handleForm(data: any) {
            Helper.getTracker()?.push({
                event: 'formsubmit-play',
                // @ts-ignore
                country: data["country"] as string,
                newsletter_signup: data["news"] as string
            });
            const requireVerify = ConfigModule.get().settings.emailVerification;
            await apiModule.signInAnonymousUser();
            console.log(data)
            const result = await apiModule.loginUser(LoginType.ANONYMOUS, {
                firstName: data["firstName"],
                lastName: data["lastName"],
                email: data["email"],
                terms: true,
                news: data["news"],
                country: data["country"],
                language: getLanguage()
            })

            //Go to verification page
            await this.$router.replace({
                name: PageName.WALKTHROUGH
            });
        }
    }
});
