
import NormalButton from "@/components/buttons/NormalButton.vue";
import RouterButton from "@/components/buttons/RouterButton.vue";
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  components: {
    NormalButton,
    RouterButton,
  },
  data() {
    return {
      isAgeGateOpen: true,
    };
  },
  methods: {
    closeAgeGate() {
      this.isAgeGateOpen = false;
    },
    notRequiredAge() {
      alert('You can not access the website if you don’t have the required legal age to drink')
    }
  },
});
