
import {PageName} from "@/utils/Constants";
import {defineComponent, PropType} from "vue";
import ConfigModule from "@/modules/ConfigModule";
import RouterButton from "@/components/buttons/RouterButton.vue";

export default defineComponent({
    components: {RouterButton},
    props: {
        showBackButton: Boolean,
        backButtonPageName: String as PropType<PageName>,
        showLogo: Boolean,
        title: String
    },
    computed: {
        backPageLink(): string {
            return ConfigModule.getPageLink(this.backButtonPageName || PageName.LAUNCH);
        }
    }
});
