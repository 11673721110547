
import {defineComponent} from "vue";
import BaseButton from "./base/BaseButton.vue";
import nativeModule from "@/modules/NativeModule";
import Helper from "@/utils/Helper";
import {sharedButtonMixin} from "@/components/buttons/base/BaseButton.vue";

export default defineComponent({
    mixins: [sharedButtonMixin],
    components: {BaseButton},
    props: {
        href: String,
        alternate: Boolean
    },
    data() {
        return {
            anchorUrl: "",
            anchorTarget: ""
        }
    },
    mounted() {
        if(this.href) {
            const isNative = nativeModule.isNative();

            //Set terms target & url
            if(isNative) {
                this.anchorUrl = Helper.getWebViewUrl(this.href);
                this.anchorTarget = "_self";
            } else {
                this.anchorUrl = this.href;
                this.anchorTarget = "_blank";
            }
        }
    },
    computed: {
        isAnchor(): string {
            return this.href ? "a" : "div";
        },
        isAlternate(): string {
            return this.alternate ? "alternate" : "";
        }
    }
});
