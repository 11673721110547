import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, resolveComponent as _resolveComponent } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_button = _resolveComponent("base-button")!

  return (_openBlock(), _createBlock(_component_base_button, {
    "full-width": !_ctx.image,
    "play-sound": ""
  }, {
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.isAnchor), {
        class: "button-content",
        href: _ctx.anchorUrl,
        target: _ctx.anchorTarget
      }, {
        default: _withCtx(() => [
          (_ctx.image)
            ? (_openBlock(), _createBlock("img", {
                key: 0,
                class: "button-image",
                src: _ctx.imageSrc,
                alt: "button image"
              }, null, 8, ["src"]))
            : (_openBlock(), _createBlock("span", {
                key: 1,
                class: ["button-default", _ctx.isAlternate]
              }, [
                _renderSlot(_ctx.$slots, "default")
              ], 2))
        ]),
        _: 3
      }, 8, ["href", "target"]))
    ]),
    _: 1
  }, 8, ["full-width"]))
}