import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_normal_button = _resolveComponent("normal-button")!
  const _component_base_dialog = _resolveComponent("base-dialog")!

  return (_openBlock(), _createBlock(_component_base_dialog, {
    copy: _ctx.copy,
    image: _ctx.image,
    callback: _ctx.callbackInternal,
    hide: _ctx.forceHide
  }, {
    content: _withCtx(() => [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.prizes, (item) => {
        return (_openBlock(), _createBlock("div", null, _toDisplayString(`1x ${item.name}`), 1))
      }), 256))
    ]),
    buttons: _withCtx(() => [
      _createVNode(_component_normal_button, {
        class: "dialog-dynamic-button",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.callbackInternal(_ctx.DialogAction.CONFIRM)))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.getGlobalTranslation("confirm")), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["copy", "image", "callback", "hide"]))
}