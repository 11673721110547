
import {Validator} from "@/utils/Validator";
import {defineComponent, PropType} from "vue";
import {translationMixin} from "@/mixins/TranslationMixin";
import {IFormInput, IFormInputCopy} from "@/interfaces/form/IFormInput";

export default defineComponent({
    mixins: [translationMixin],
    props: {
        settings: {type: Object as PropType<IFormInput>, required: true},
        modelValue: [String, Number]
    },
    emits: ["update:modelValue"],
    data() {
        return {
            translatedCopy: this.getTranslatedCopy<IFormInputCopy>(this.settings.copy),
            internalValue: this.modelValue ? String(this.modelValue) : "",
            error: false,
            errorMessage: "-"
        }
    },
    created() {
      console.log(this.settings)
    },
    methods: {
        validate(): boolean {
            let value = (this.internalValue || "").trim();

            //Check if required
            if(!this.settings.optional && !value) {
                return this.update(value, true, "This field is required!");
            }

            //Validate
            if(!Validator.validate(this.settings.type, value)) {
                return this.update(value, true, "Please enter a valid value!");
            }

            //Update value
            return this.update(value);
        },
        update(value: string, error: boolean = false, errorMessage: string = "-"): boolean {
            if(error) {
                this.errorMessage = errorMessage;
            }
            this.error = error;
            this.$emit("update:modelValue", !error ? value : "");
            return !error;
        }
    },
    computed: {
        inputIcon(): string {
            switch(this.settings.icon) {
                default:
                case "name":
                    return require("../../assets/images/icon-input-name.png");
                case "email":
                    return require("../../assets/images/icon-input-email.png");
                case "password":
                    return require("../../assets/images/icon-input-password.png");
            }
        },
        errorMode(): string {
            return this.error ? "error" : "ready";
        }
    }
});
