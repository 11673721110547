
import {defineComponent} from "vue";
import BaseButton from "./base/BaseButton.vue";
import {sharedButtonMixin} from "@/components/buttons/base/BaseButton.vue";

export default defineComponent({
    mixins: [sharedButtonMixin],
    components: {BaseButton},
    props: {
        to: {type: String, required: true},
        alternate: Boolean,
        dark: Boolean,
        external: Boolean
    },
    computed: {
        isAlternate(): string {
            return this.alternate ? "alternate" : "";
        },
        isDark(): string {
            return this.dark ? "dark" : "";
        }
    }
});
