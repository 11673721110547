//All available page names, mainly used for navigation or config purposes
export enum PageName {
    SETUP = "setup",
    WALKTHROUGH = "walkthrough",
    LOGIN = "login",
    REGISTER = "register",
    REGISTER_VERIFY = "register-verify",
    LAUNCH = "launch",
    SETTINGS = "settings",
    GAME = "game",
    SCORE = "score",
    LEADERBOARD = "leaderboard",
    STORE = "store",
    STORE_DETAIL = "store-detail"
}

//Available leaderboard types - options only work when also enabled in API
export enum LeaderboardType {
    DAILY = "daily",
    WEEKLY = "weekly",
    TOTAL = "total"
}

//Possible login types
export enum LoginType {
    ANONYMOUS = "anonymous",
    EMAIL = "email",
    CUSTOM = "custom"
}

//Available dialog types
export enum DialogName {
    CHECK_PRIZE = "checkPrize",
    GAME_OVER = "gameOver",
    HINT = "hint"
}

//Default dialog actions (callback types)
export enum DialogAction {
    CLOSE = "close",
    CONFIRM = "confirm",
    DENY = "deny"
}