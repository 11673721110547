
import LeaderboardItem from "@/components/lists/LeaderboardItem.vue";
import LogoLoader from "@/components/loaders/LogoLoader.vue";
import {ILeaderboard} from "@/interfaces/api/ILeaderboard";
import apiModule from "@/modules/APIModule";
import {LeaderboardType, PageName} from "@/utils/Constants";
import {defineComponent} from "vue";
import ConfigModule from "@/modules/ConfigModule";
import {translationMixin} from "@/mixins/TranslationMixin";
import {IPage, IPageCopy} from "@/interfaces/IPage";
import CustomHeader from "@/components/CustomHeader.vue";

interface LeaderboardPage extends IPage {
    type: LeaderboardType
}

export default defineComponent({
    mixins: [translationMixin],
    components: {
        CustomHeader,
        LogoLoader,
        LeaderboardItem
    },
    data() {
        const data = ConfigModule.getPageByName(PageName.LEADERBOARD) as LeaderboardPage;
        return {
            PageName,
            translatedCopy: this.getTranslatedCopy<IPageCopy>(data.copy),
            type: data.type,
            items: [] as ILeaderboard[],
            personalHighscore: null as ILeaderboard | null
        }
    },
    mounted() {
        apiModule.getLeaderboard(this.type).then(response => {
            this.items = response["results"];
            this.personalHighscore = response["personalHighScore"] || null;
        });
    },
    computed: {
        launchLink(): string {
            return ConfigModule.getPageLink(PageName.LAUNCH);
        }
    }
});
