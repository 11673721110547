import Phaser from "phaser";
import WebFont from "webfontloader";
import Constants from "../configs/constants";
import Scaling from "../configs/scaling";

export default class Boot extends Phaser.Scene {

    constructor() {
        super({
            key: "boot"
        });
    }

    preload() {
        // Load Fonts //
        this.load.on("complete", () => {
            WebFont.load({
                custom: {
                    families: [Constants.FONT_BOLD, Constants.FONT_BOLD_ITALIC, Constants.FONT_REGULAR]
                },
                active: () => this.booted(true),
                inactive: () => this.booted(false)
            });
        });

        // Load images //
        this.load.image('logo', Scaling.ImagePath('images/game/chouffe_findmarcel', 'png'));
    }

    booted(fontsLoaded) {
        // Fonts: Loaded check //
        if (!fontsLoaded) {
            return console.error("fonts failed to load...");
        }

        // Scene: launch parallel background scene //
        // NOTE: When not using background, you can place the booted event here
        this.scene.launch('background');
        this.scene.stop();
    }
}
