
import {defineComponent} from "vue";

export default defineComponent({
    props: {
        rank: Number,
        name: String,
        score: Number
    },
    methods: {
        getImageByRank() {
            switch (this.rank) {
                case 1:
                    return require("../../assets/images/icon-medal-one.png");
                case 2:
                    return require("../../assets/images/icon-medal-two.png");
                case 3:
                    return require("../../assets/images/icon-medal-three.png");
            }
        },
        isHighlight() {
            return this.rank === 1 ? "highlight" : "";
        }
    }
});
