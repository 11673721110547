import Phaser from "phaser";
import Scaling from "../configs/scaling";
import Constants from "../configs/constants";

export default class Main extends Phaser.Scene {

    constructor() {
        super({ key: "game" });
    }

    create() {
        this.isGameOver = false;
        this.gameTimer = 30;
        this.endScore = 0;

        // Game Events: start even fired //
        this.game.events.emit('start');

        this.physics.world.setBounds(0, 0, 1080 * Scaling.DPR, 1080 * Scaling.DPR)

        this.uiCamera = this.cameras.add(0, 0, this.cameras.main.width, this.cameras.main.height);
        this.cameras.main.setBounds(0, 0, 1080 * Scaling.DPR, 1080 * Scaling.DPR);
        this.cameras.main.setZoom(1);
        this.cameras.main.setScroll((1080 * Scaling.DPR / 2) - (this.cameras.main.width / 2), (1080 * Scaling.DPR / 2) - (this.cameras.main.height / 2))

        // Create background
        this.background = this.add.image(0, 0, 'background_image').setSize(1080 * Scaling.DPR, 1080 * Scaling.DPR).setOrigin(0, 0);
        this.background.setAlpha(0);

        // Create logo
        this.logo = this.add.image(this.cameras.main.width / 2, 24 * Scaling.DPR, 'logo_text').setOrigin(0.5, 0);

        // Create timer
        this.timerBg = this.add.image(this.cameras.main.width / 2, 56 * Scaling.DPR, 'timer_background').setOrigin(0.5, 0);
        this.timerBg.setAngle(5);
        this.timerBg.setAlpha(0)

        this.timerCopy = this.add.text(this.cameras.main.width / 2, 56 * Scaling.DPR, '00:30', {
            fontFamily: Constants.FONT_BOLD_ITALIC,
            fontSize: 32 * Scaling.DPR,
            resolution: Scaling.DPR,
            color: 0x442814,
            align: "center"
        })
        this.timerCopy.setOrigin(0.5, 0);
        this.timerCopy.setAngle(5);
        this.timerCopy.setAlpha(0)

        this.hurryCopy = this.add.text(this.cameras.main.width / 2, 120 * Scaling.DPR, 'Hurry!', {
            fontFamily: Constants.FONT_BOLD_ITALIC,
            fontSize: 56 * Scaling.DPR,
            resolution: Scaling.DPR,
            color: '#ffffff',
            align: "center"
        })
        this.hurryCopy.setOrigin(0.5, 0);
        this.hurryCopy.setAngle(5);
        this.hurryCopy.setAlpha(0);

        // Start Countdown
        this.startCountdown();

        // Set timer to save some random data
        this.time.addEvent({
            delay: 1000,
            callback: () => this.saveData()
        });
    };

    startCountdown() {
        this.countdownTimer = 3;

        this.instructionText = this.add.text(this.cameras.main.width / 2, this.cameras.main.height - 24 * Scaling.DPR, "Tip: Click and drag to navigate and find Marcel!", {
            fontFamily: Constants.FONT_REGULAR,
            fontSize: 14 * Scaling.DPR,
            resolution: Scaling.DPR,
            color: 0x442814,
            wordWrap: { width: 160 * Scaling.DPR, useAdvancedWrap: true },
            maxLines: 2,
            align: "center"
        });
        this.instructionText.setOrigin(0.5, 1);

        this.countdown = this.add.text(this.cameras.main.width / 2, this.cameras.main.height / 2, this.countdownTimer, {
            fontFamily: Constants.FONT_BOLD_ITALIC,
            fontSize: 88 * Scaling.DPR,
            resolution: Scaling.DPR,
            color: 0x442814,
            align: "center"
        });
        this.countdown.setOrigin(0.5, 0.5);

        this.tweens.add({
            targets: this.countdown,
            scale: 1.8,
            alpha: { from: 1, to: 0 },
            ease: 'EaseInOut',
            duration: 700,
            hold: 100,
            repeat: 0,
        })

        this.countdownTime = this.time.addEvent({
            delay: 800,
            loop: true,
            callback: () => {
                if (this.countdownTimer > 1) {
                    this.countdownTimer--;
                    this.countdown.setScale(1);
                    this.countdown.setText(this.countdownTimer);

                    this.tweens.add({
                        targets: this.countdown,
                        scale: 1.8,
                        alpha: { from: 1, to: 0 },
                        ease: 'EaseInOut',
                        duration: 700,
                        repeat: 0,
                    })
                } else {
                    this.countdownTime.remove(false)
                    this.countdown.setScale(1);
                    this.countdown.setText('Go!');

                    this.tweens.add({
                        targets: this.instructionText,
                        alpha: { from: 1, to: 0 },
                        ease: 'EaseInOut',
                        durtation: 100,
                        repeat: 0
                    })

                    this.startGame();
                }
            }
        })
    }

    startGame() {
        this.startTime = Date.now();

        this.tweens.add({
            targets: [this.background, this.timerCopy, this.timerBg],
            alpha: { from: 0, to: 1 },
            ease: 'EaseInOut',
            durtation: 100,
            repeat: 0
        })

        this.time.addEvent({
            delay: 200,
            callback: () => {
                this.tweens.add({
                    targets: this.countdown,
                    alpha: { from: 1, to: 0 },
                    ease: 'EaseInOut',
                    duration: 300,
                    repeat: 0
                })
            }
        })

        // Set clickable area
        this.interactiveArea = this.add.rectangle(770 * Scaling.DPR, 340 * Scaling.DPR, 120 * Scaling.DPR, 130 * Scaling.DPR).setInteractive().setOrigin(0, 0);

        // Outline if guessed correctly
        this.correctOutline = this.add.image(770 * Scaling.DPR, 330 * Scaling.DPR, 'scribble').setOrigin(0, 0).setAlpha(0);

        // Start game timer
        this.gameTimerEvent = this.time.addEvent({
            delay: 1000,
            loop: true,
            callback: () => {
                this.gameTimer--;
                this.timerCopy.setText(`00:${this.gameTimer.toLocaleString('nl-NL', { minimumIntegerDigits: 2, useGrouping: false })}`)

                if (this.gameTimer == 10) {
                    this.tweens.add({
                        targets: this.hurryCopy,
                        alpha: { from: 0, to: 1 },
                        ease: 'EaseInOut',
                        duration: 100,
                        hold: 1800,
                        yoyo: true,
                    })
                }

                if (this.gameTimer <= 10) {
                    this.timerCopy.setColor('#ff0000');
                }

                if (this.gameTimer <= 0) {
                    this.gameOver(false)
                }
            }
        })

        this.interactiveArea.on('pointerdown', () => {
            this.gameTimerEvent.remove();
            this.endScore = Date.now() - this.startTime;

            this.tweens.add({
                targets: this.correctOutline,
                alpha: { from: 0, to: 1 },
                ease: 'EaseInOut',
                durtation: 300,
            })

            this.time.addEvent({
                delay: 2000,
                callback: () => {
                    this.gameOver(true);
                }
            })
        })

        this.input.on("pointermove", (p) => {
            if (!p.isDown) return;

            this.cameras.main.scrollX -= (p.x - p.prevPosition.x) / this.cameras.main.zoom;
            this.cameras.main.scrollY -= (p.y - p.prevPosition.y) / this.cameras.main.zoom;
        });

        this.uiCamera.ignore([this.background, this.interactiveArea]);
        this.cameras.main.ignore([this.logo, this.timerBg, this.timerCopy]);
    }

    update(time, delta) {

    }

    saveData() {
        const saveData = this.game.registry.get("saveData") || {};

        //Copy last value & write new one
        if ("value" in saveData) {
            saveData["lastValue"] = saveData.value;
        }
        saveData["value"] = Phaser.Math.RND.integerInRange(0, 100000);

        //Send save event
        this.game.events.emit("save", saveData);
    }

    gameOver(completed) {
        this.gameCleanup(completed);
    }

    gameCleanup(completed) {
        this.isGameOver = true;
        this.time.removeAllEvents();

        this.shutdown(completed);
    }

    shutdown(completed) {
        //Transmit end
        this.game.events.emit('end', {
            score: this.endScore,
            level: completed ? 1 : 0
        });

        // Stop scene //
        this.scene.stop();
    }
}