import {PageName} from "@/utils/Constants";
import GameView from "@/views/GameView.vue";
import LaunchView from "@/views/LaunchView.vue";
import LeaderboardView from "@/views/LeaderboardView.vue";
import LoginView from "@/views/LoginView.vue";
import RegisterVerifyView from "@/views/RegisterVerifyView.vue";
import RegisterView from "@/views/RegisterView.vue";
import ScoreView from "@/views/ScoreView.vue";
import SettingsView from "@/views/SettingsView.vue";
import SetupView from "@/views/SetupView.vue";
import StoreDetailView from "@/views/StoreDetailView.vue";
import StoreView from "@/views/StoreView.vue";
import WalkthroughView from "@/views/WalkthroughView.vue";
import {createRouter, createWebHistory, RouteLocation, RouteRecordRaw} from "vue-router";
import ConfigModule from "@/modules/ConfigModule";

//Get routing information
const pages = ConfigModule.get().pages;

//JSON to props parser
const propsParser = (route: RouteLocation) => {
    const rawData = route.params["data"] || route.query["data"] || null;
    if(rawData) {
        const parsedData = JSON.parse(rawData as string);
        return {
            routeData: parsedData
        }
    }
};

//Generate routes
const routes: RouteRecordRaw[] = pages.map((page, index) => {
    const name = page.name;

    //Set base route
    const route: RouteRecordRaw | any = {
        name: name,
        path: `/${name}`,
        ...(index === 0 ? {alias: "/"} : {})
    };

    //Add components and other settings to route, based on name
    switch(name) {
        case PageName.SETUP:
            route.component = SetupView;
            break;
        case PageName.WALKTHROUGH:
            route.component = WalkthroughView;
            break;
        case PageName.LOGIN:
            route.component = LoginView;
            break;
        case PageName.REGISTER:
            route.component = RegisterView;
            break;
        case PageName.REGISTER_VERIFY:
            route.component = RegisterVerifyView;
            route.props = propsParser;
            break;
        case PageName.LAUNCH:
            route.component = LaunchView;
            break;
        case PageName.SETTINGS:
            route.component = SettingsView;
            break;
        case PageName.LEADERBOARD:
            route.component = LeaderboardView;
            break;
        case PageName.GAME:
            route.component = GameView;
            break;
        case PageName.SCORE:
            route.component = ScoreView;
            route.props = propsParser;
            break;
        case PageName.STORE:
            route.component = StoreView;
            break;
        case PageName.STORE_DETAIL:
            route.component = StoreDetailView;
            route.props = propsParser;
            break;
    }

    //Return route entry
    return route;

}).filter(route => route !== null);


//Create router
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: routes
});
export default router;