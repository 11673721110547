
import CustomFooter from "@/components/CustomFooter.vue";
import CustomHeader from "@/components/CustomHeader.vue";
import WalkthroughIndicator from "@/components/walkthrough/WalkthroughIndicator.vue";
import WalkthroughItem from "@/components/walkthrough/WalkthroughItem.vue";
import {IWalkthroughItem} from "@/interfaces/components/IWalkthroughItem";
import {IPage} from "@/interfaces/IPage";
import {PageName} from "@/utils/Constants";
import {defineComponent} from "vue";
import {translationMixin} from "@/mixins/TranslationMixin";
import {stateMixin} from "@/mixins/StateMixin";
import ConfigModule from "@/modules/ConfigModule";
import NormalButton from "@/components/buttons/NormalButton.vue";

//Page interface
interface IWalkthroughPage extends IPage {
    content: IWalkthroughItem[]
}

//Observer instance
let observer: IntersectionObserver | null;

//Component
export default defineComponent({
    mixins: [translationMixin, stateMixin],
    components: {
        NormalButton,
        CustomFooter,
        CustomHeader,
        WalkthroughItem,
        WalkthroughIndicator
    },
    data() {
        const data = ConfigModule.getPageByName(PageName.WALKTHROUGH) as IWalkthroughPage;
        const itemData = Array.from(data.content) as IWalkthroughItem[];

        //Check route data, if we should skip last item
        if(parseInt(this.$route.query["skipLast"] as string)) {
            itemData.pop();
        }

        //Return things
        return {
            items: itemData,
            itemRefs: [] as any[],
            activeIndex: 0
        };
    },
    mounted() {
        observer = new IntersectionObserver(entries => this.handleObserverCallback(entries), {
            root: this.$refs.container as HTMLDivElement,
            rootMargin: "0px",
            threshold: 0.5
        });

        //Attach items
        for(const itemRef of this.itemRootRefs) {
            observer.observe(itemRef);
        }
    },
    unmounted() {
        if(observer) {
            observer.disconnect();
            observer = null;
        }

        //Mark page as seen
        this.setElementShown(PageName.WALKTHROUGH);
    },
    methods: {
        handleObserverCallback(entries: IntersectionObserverEntry[]): void {
            let visibleIndex = -1;
            for(const entry of entries) {
                if(entry.isIntersecting) {
                    visibleIndex = this.itemRootRefs.indexOf(entry.target as HTMLDivElement);
                    break;
                }
            }
            //Update active index
            if(visibleIndex !== -1) {
                this.activeIndex = visibleIndex;
            }
        },
        goToPosition(index: number): void {
            const target = (this.$refs.container as HTMLDivElement).children.item(index);
            target?.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
        }
    },
    computed: {
        itemRootRefs(): HTMLDivElement[] {
            return this.itemRefs.map(ref => ref.$refs.root as HTMLDivElement);
        },
        showSkipButton(): string {
            return this.activeIndex !== this.items.length - 1 ? "show" : "hide";
        }
    }
});
