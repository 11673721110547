
import {PageName} from "@/utils/Constants";
import {defineComponent, PropType} from "vue";
import {IFormBase} from "@/interfaces/form/IFormBase";
import CustomForm from "@/components/form/CustomForm.vue";
import {IWalkthroughItemCopy} from "@/interfaces/components/IWalkthroughItem";
import {translationMixin} from "@/mixins/TranslationMixin";
import ConfigModule from "@/modules/ConfigModule";
import NormalButton from "@/components/buttons/NormalButton.vue";

export default defineComponent({
    components: {NormalButton, CustomForm},
    mixins: [translationMixin],
    props: {
        lastItem: {type: Boolean, required: true},
        image: {type: String, required: true},
        copy: Object as PropType<IWalkthroughItemCopy>,
        formContent: Object as PropType<IFormBase[]>
    },
    data() {
        return {
            translatedCopy: this.getTranslatedCopy<IWalkthroughItemCopy>(this.copy),
            buttonCopy: this.getGlobalTranslation(this.formContent ? "accept" : "Start")
        }
    },
    methods: {
        openNextPage() {
            this.$router.replace(ConfigModule.getPageLink(PageName.GAME));
        }
    }
});
