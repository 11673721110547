
import {DialogAction} from "@/utils/Constants";
import {defineComponent, PropType} from "vue";
import {translationMixin} from "@/mixins/TranslationMixin";
import {IDialogCopy} from "@/interfaces/IDialog";
import NormalButton from "@/components/buttons/NormalButton.vue";

//Mixin with shared elements between base and extended dialogs
export const sharedDialogMixin = defineComponent({
    data() {
        return {
            DialogAction,
            confirmMode: false,
            sharedData: null as any,
            forceHide: false
        }
    }
});

//Base definition
export default defineComponent({
    components: {NormalButton},
    mixins: [translationMixin, sharedDialogMixin],
    props: {
        callback: {type: Function as PropType<(action: DialogAction, data?: any) => void>, required: true},
        copy: {type: Object as PropType<IDialogCopy>, required: true},
        image: String,
        confirmImage: String,
        showConfirmation: Boolean,
        dialogData: Object as PropType<any>,
        hide: Boolean
    },
    emits: [DialogAction.DENY],
    data() {
        return {
            translatedCopy: this.getTranslatedCopy<IDialogCopy>(this.copy)
        }
    },
    computed: {
        getConfirmationImage(): string | undefined {
            return this.confirmImage ? this.confirmImage : this.image;
        }
    }
});
