import {IUser, IUserResponse, IUserUpdate} from "@/interfaces/api/IUser";
import {LeaderboardType, LoginType} from "@/utils/Constants";
import Helper from "@/utils/Helper";
import axios, {AxiosResponse} from "axios";
import firebase from "firebase/app";
import "firebase/auth";
import Watcher from "@/utils/Watcher";
import Error = firebase.auth.Error;

//Firebase instance
const app: firebase.app.App = firebase.initializeApp({
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN
});


/**
 * Module for API calls
 */
class APIModule {

    //Firebase
    private signInWatcher = new Watcher<boolean>();

    //Retry
    private static MAX_RETRIES: number = 5;
    private static RETRY_DELAY: number = 1000;

    /**
     * Constructor, begins listening for whether user is already signed in
     */
    constructor() {
        app.auth().onAuthStateChanged(user => {
            this.signInWatcher.updateValue(user !== null);
        });
    }

    /**
     * Create a Firebase user based on provided email and password - works for creation only! Sign-in is separate unlike anonymous/custom users
     * @param email user e-mailaddress
     * @param password user password
     * @param sendVerification whether a verification mail will be send (should be set the same as how API is configured)
     */
    async createEmailUser(email: string, password: string, sendVerification: boolean = false) {
        const credentials = await app.auth().createUserWithEmailAndPassword(email, password);

        //Send verification mail, if enabled
        if(credentials.user && sendVerification) {
            await credentials.user.sendEmailVerification();
        }
    }

    /**
     * Sign-in as an email Firebase user, with provided email and password
     */
    async signInEmailUser(email: string, password: string) {
        if (this.signInWatcher.getValue()) {
            return;
        }
        await app.auth().signInWithEmailAndPassword(email, password);
    }

    /**
     * Create/sign-in as an anonymous Firebase user
     */
    async signInAnonymousUser() {
        if (this.signInWatcher.getValue()) {
            return;
        }
        await app.auth().signInAnonymously();
    }

    /**
     * Create/sign-in as a custom Firebase user, based on some external value
     * @param uid the external value, ideally some sort of token which can be validated server-side
     */
    async signInCustomUser(uid: string) {
        if (this.signInWatcher.getValue()) {
            return;
        }

        //Get token from email
        const result = await this.request("POST", "user/register/custom", {uid: uid});
        await app.auth().signInWithCustomToken(result["token"]);
    }

    /**
     * After signing in into Firebase, this is used to login to our own API
     * @param type the type of login used as enum value (email, custom, anonymous)
     * @param userData the user data to perform API log-in with - for first-time login you must always provide user data, but can be omitted afterwards (or you can just send it and API will update relevant things)
     */
    async loginUser(type: LoginType, userData?: IUser): Promise<IUserResponse> {
        const result = await this.request("POST", `user/login/${type}`, userData);

        //Force refresh token (needed for custom claims) and return result
        await this.getUser()?.getIdToken(true);
        return result;
    }

    /**
     * Update user data, this is typically used for saving language or state of UI and game
     * Please note that this call overwrites values that are provided! E.g. setting saveData in data will overwrite it server-side, but omitting it completely will leave server-side saveData untouched
     * @param data the data to update user with
     */
    async updateUser(data: IUserUpdate) {
        await this.request("PUT", "user", data);
    }

    /**
     * Call used to initiate a score/game session
     * @param level the level of the current session
     * @returns response return response body, which includes a score token used for the endGame call (expires in 8 hours)
     */
    beginGame(level: number) {
        return this.request("POST", "score", {
            level: level,
            startTime: Helper.getAPIDate()
        });
    }

    /**
     * Call used to finish a game session
     * @param token the token given by the beginGame call (can only be used once)
     * @param score the score of just finished game session
     * @param gameData optional but recommended to provide - should include data to recalculate the score value server-side
     * @returns response response has no real use
     */
    endGame(token: string, score: number, gameData?: any) {
        return this.request("PUT", `score`, {
            token: token,
            score: score,
            endTime: Helper.getAPIDate(),
            ...(gameData ? {gameData: gameData} : {})
        });
    }

    /**
     * Get all available prizes
     * @returns response list of all available prizes
     */
    getPrizeList() {
        return this.request("GET", "prize/list");
    }

    /**
     * Claim a prize ID, this is used for client-based prize claiming and will be marked as such
     * @param id the prize ID, provided by getPrizeList
     * @returns response response has no real use
     */
    claimPrize(id: number) {
        return this.request("POST", `prize/claim/${id}`);
    }

    /**
     * Get all prizes you have won
     * @param claimed whether you want to retrieve all your claimed prizes, or unclaimed prizes
     * @returns response list of all claimed/unclaimed prizes
     */
    getPersonalPrizeList(claimed: boolean = false) {
        return this.request("GET", "prize/list/personal", {claimed: claimed ? 1 : 0});
    }

    /**
     * Confirm a prize as claimed
     * @param id an ID of a prize provided by the getPersonalPrizeList api
     * @returns response response has no real use
     */
    confirmPrize(id: number) {
        return this.request("POST", `prize/confirm/${id}`);
    }

    /**
     * Subtract currency from your account - mainly used currently for logging purposes
     * @param amount the amount of current you want to spend (must be positive)
     * @returns response response has no real use
     */
    subtractCurrency(amount: number) {
        return this.request("POST", "currency/subtract", {currency: -amount});
    }

    /**
     * Get leaderboard by provided type
     * @param type the type of leaderboard you want (daily, weekly, total), note however that the type must be enabled by server
     * @returns response list of top 10 and your personal ranking if you're not in the top 10
     */
    getLeaderboard(type: LeaderboardType, level: number = -1) {
        return this.request("GET", `score/leaderboard/${type}`, {
            ...(level !== -1 ? {level: level} : {})
        });
    }

    /**
     * Internal request function, handles things like retries & token
     * @param method the request method (e.g. POST or GET)
     * @param path the path to required endpoint (is appended to base URL set in .env)
     * @param data request data, will be automatically converted to query for GET or JSON body for POST-like requests
     * @returns response returns the response body
     */
    private async request(method: string, path: string, data?: any) {
        const normalizedMethod = method.toLowerCase();
        let result: AxiosResponse | null = null;
        let attempt = 0;
        let lastError: Error | undefined;
        let token: string | undefined;

        //Try multiple attempts to retrieve data
        while (!result && attempt < APIModule.MAX_RETRIES)
        {
            //Get token, if not already found & logged in
            if(!token && this.signInWatcher.getValue()) {
                token = await this.getUser()?.getIdToken();
            }

            //Try to do call
            try {
                result = await axios(`${process.env.VUE_APP_API_URL}/${path}`, {
                    method: normalizedMethod as any,

                    //Set data
                    ...(normalizedMethod === "get" && data ? {params: data} : {}),
                    ...(normalizedMethod !== "get" && data ? {data: data} : {}),

                    //Set headers
                    headers: {
                        ...(token ? {"Authorization": `Bearer ${token}`} : {})
                    }
                });

                //Reset error
                lastError = undefined;

            } catch (e) {
                console.warn(`Request failed, will retry after ${APIModule.RETRY_DELAY}ms...`);
                lastError = e;
                attempt++;
                await Helper.sleep(APIModule.RETRY_DELAY);
            }
        }

        //Throw exception when error is found
        if(lastError) {
            throw lastError;
        }

        //Return result
        return result ? result.data : null;
    }

    /**
     * Used for waiting until a sign-in event has triggered (always happens with Firebase, even if not logged in)
     * @returns boolean whether the user is signed-in or not
     */
    waitForSignIn(): Promise<boolean> {
        return new Promise((resolve) => {
            const signedIn = this.signInWatcher.getValue();
            if(signedIn !== undefined) {
                return resolve(signedIn);
            }

            //Listen for change
            this.signInWatcher.setListener((newValue) => resolve(newValue), true);
        });
    }

    /**
     * Getter for the Firebase user
     * @returns user returns a Firebase user object
     */
    getUser() {
        return app.auth().currentUser;
    }
}

const apiModule = new APIModule();
export default apiModule;