import * as Sentry from "@sentry/browser";
import {Integrations} from "@sentry/tracing";
import {polyfill as smoothScrollPolyfill} from "seamless-scroll-polyfill";
import {createApp} from "vue";
import App from "./App.vue";
import router from "./router";
import Helper from "@/utils/Helper";
import nativeModule from "@/modules/NativeModule";

//Polyfills
smoothScrollPolyfill();

//Create app
const app = createApp(App);

//Set base
app.use(router);
app.mount("#app");

//Force first page
if(Helper.isFlowForced()) {
    router.replace("/").catch(e => console.error(e));
}

//Set listener for page focus, set full-screen if true
document.addEventListener("visibilitychange", async () => {
    if(!document.hidden) {
        await Helper.sleep(100);
        await nativeModule.triggerFullScreen();
    }
});

//Sentry - enable
Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
        new Integrations.BrowserTracing()
    ],
    tracesSampleRate: 1
});

//Sentry - Vue error capturing
app.config.errorHandler = ((err, _, info) => {
    Sentry.setTag("info", info);
    Sentry.captureException(err);
    console.error(err);
});