import {NativeBridge, NativeUser} from "@/interfaces/IBridge";
import Watcher from "@/utils/Watcher";

/**
 * Module used for native functionality (like an app bridge)
 * Current implementation is for the McDo app, but should be replaced/updated when used in another
 */
export class NativeModule {

    //Settings
    private allowLanguages: string[] = ["en", "nl", "fr"];

    //Bridge
    private bridge: NativeBridge | null = null;
    private bridgeWatcher = new Watcher<boolean>();
    private bridgeTimeout: number;

    /**
     * Constructor, begins listening for bridge with certain timeout
     */
    constructor() {
        this.bridgeTimeout = setTimeout(() => {
            this.bridgeWatcher.updateValue(false);
        }, parseInt(process.env.VUE_APP_BRIDGE_TIMEOUT));

        //Listen for bridge
        document.addEventListener("mcdBridgeReady", this.bridgeEventHandler.bind(this));
    }

    /**
     * Get user data via bridge, or return dummy user when bridge wasn't found
     * @returns NativeUser native user data, although can be null if it fails while bridge was detected
     */
    getUser() {
        return new Promise<NativeUser | null>(resolve => {
            const handle = this.bridge?.message("user");
            if (!handle) {
                return resolve(NativeModule.getFallbackUser());
            }

            //Send instruction & process
            handle.send({getuser: true});
            handle.on("data", (data: NativeUser) => resolve(data));

            //Handle error
            handle.on("error", (e: Event) => {
                console.error("Native user get error:", e);
                resolve(null);
            });
        });
    }

    /**
     * Get user language via bridge, or return default language when bridge wasn't found
     * Note that it will check whether the native language is supported, otherwise it'll fallback to english
     * @returns string the resulting language
     */
    getUserLanguage() {
        return new Promise<string>(resolve => {
            const handle = this.bridge?.message("system");
            if (!handle) {
                let language = process.env.VUE_APP_DEFAULT_LANGUAGE || "en";
                if (!this.allowLanguages.includes(language)) {
                    language = this.allowLanguages[0];
                }
                return resolve(language);
            }

            //Send instruction & process
            handle.send({"getSelectedLanguage": true});
            handle.on("data", (data: any) => {
                let language = data.language.split("-")[0];
                if (!this.allowLanguages.includes(language)) {
                    language = this.allowLanguages[0];
                }
                resolve(language);
            });
        });
    }


    /**
     * Trigger the login screen, should be called when getUser returns null
     * After successful login, it will return the NativeUser object like the getUser call
     * @returns NativeUser native user data, although can be null if user fails to log-in
     */
    triggerLogin() {
        return new Promise<NativeUser | null>(resolve => {
            const handle = this.bridge?.message("user");
            handle?.send({"promptlogin": true});
            handle?.on("data", (data: NativeUser) => resolve(data));

            //Handle error
            handle?.on("error", (e: Event) => {
                console.error("Native user sign-in error:", e);
                resolve(null);
            });
        });
    }

    /**
     * Trigger full-screen
     * @param enabled default true - true is enable fullscreen, false is disable fullscreen
     */
    triggerFullScreen(enabled: boolean = true) {
        return new Promise<void>(resolve => {
            const handle = this.bridge?.message("system");
            handle?.send({"fullscreen": enabled});
            return resolve();
        });
    }

    /**
     * Claim a coupon code
     * @param id the ID of the coupon code to claim
     */
    claimCoupon(id: number) {
        return new Promise<void>((resolve, reject) => {
            const handle = this.bridge?.message("offerActivation");
            if (!handle) {
                return resolve();
            }

            //Handle
            handle.send({loyaltyId: process.env.VUE_APP_LOYALTY_ID, rewardId: id, autoActivate: false});
            handle.on("error", (e: Event) => reject(e));
            handle.on("done", () => resolve());
        });
    }

    /**
     * Get the current amount of MyM for the user
     * @returns number the amount of points you have (without bridge it defaults to 100 for testing)
     */
    getMyMPoints() {
        return new Promise<number>(resolve => {
            const handle = this.bridge?.message("deals");
            if (!handle) {
                return resolve(100);
            }

            //Send instruction & process
            handle.send({"getPoints": true});
            handle.on("data", (data: any) => resolve("points" in data ? parseInt(data["points"]) : 0));

            //Handle error
            handle.on("error", (e: Event) => {
                console.error("Native user get loyalty points error:", e);
                resolve(0);
            });
        });
    }

    /**
     * Burn a certain amount of points
     * @param amount the amount of points that need to be burned (should be a positive value, as it's automatically converted to negative)
     */
    burnMyMPoints(amount: number) {
        return new Promise<void>((resolve, reject) => {
            const handle = this.bridge?.message("deals");
            if (!handle) {
                return resolve();
            }

            //Handle
            handle.send({"burnPoints": true, points: -amount});
            handle.on("error", (e: Event) => reject(e));
            handle.on("done", () => resolve());
        });
    }


    /**
     * Getter for checking if running in native mode (based on whether a bridge was found)
     * @returns boolean whether the app is running in native mode
     */
    isNative(): boolean {
        return this.bridge !== null;
    }

    /**
     * Used for waiting until a bridge has been detected (or when a timeout occurs)
     * @returns boolean whether bridge was detected or not
     */
    waitForBridge(): Promise<boolean> {
        return new Promise((resolve) => {
            const hasBridge = this.bridgeWatcher.getValue();
            if(hasBridge !== undefined) {
                return resolve(hasBridge);
            }

            //Listen for change
            this.bridgeWatcher.setListener((newValue) => resolve(newValue), true);
        });
    }

    /**
     * Internal event handler for when bridge is detected
     */
    private bridgeEventHandler(): void {
        clearTimeout(this.bridgeTimeout);
        this.bridgeTimeout = 0;

        //Set bridge variable and watch value
        this.bridge = NativeModule.getBridge();
        this.bridgeWatcher.updateValue(true);
    }

    /**
     * Getter used to get (typed) bridge handle
     */
    private static getBridge(): NativeBridge | null {
        const windowAny = window as any;
        if ("mcd" in windowAny && "bridge" in windowAny["mcd"]) {
            return windowAny["mcd"]["bridge"] as NativeBridge;
        }
        return null;
    }

    /**
     * Fallback user creator
     */
    private static getFallbackUser(): NativeUser {
        return {
            firstname: process.env.VUE_APP_DEFAULT_FIRST_NAME || "Test",
            lastname: process.env.VUE_APP_DEFAULT_LAST_NAME || "User",
            email: process.env.VUE_APP_DEFAULT_EMAIL || "test@user.com",
            mcdonaldsId: "123abc"
        };
    }
}

const nativeModule = new NativeModule();
export default nativeModule;