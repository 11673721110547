
import CustomFooter from "@/components/CustomFooter.vue";
import CustomHeader from "@/components/CustomHeader.vue";
import CustomInput from "@/components/form/CustomInput.vue";
import {IStore} from "@/interfaces/api/IStore";
import {PageName} from "@/utils/Constants";
import Helper from "@/utils/Helper";
import {defineComponent, PropType} from "vue";
import {IFormInput} from "@/interfaces/form/IFormInput";
import ConfigModule from "@/modules/ConfigModule";
import {translationMixin} from "@/mixins/TranslationMixin";
import NormalButton from "@/components/buttons/NormalButton.vue";

export default defineComponent({
    mixins: [translationMixin],
    components: {
        NormalButton,
        CustomInput,
        CustomHeader,
        CustomFooter
    },
    props: {
        routeData: {type: Object as PropType<IStore>, required: true}
    },
    data() {
        return {
            PageName,
            title: this.routeData.name,
            description: this.routeData.description,
            phoneNumber: "",
            inputSettings: {
                name: "phoneNumber",
                type: "tel",
                copy: {
                    placeholder: "+31612345678"
                },
                image: "email"
            } as IFormInput
        }
    },
    mounted() {
        if(!this.routeData) {
            console.warn("no store item provided, returning to store...");
            this.$router.replace(ConfigModule.getPageLink(PageName.STORE));
        }
    },
    methods: {
        claimProduct(): void {
            if(!this.phoneNumber) {
                return;
            }

            //TODO: Add actual API call
            Helper.sleep(500).then(() => {
                alert("Coupon sent!");
            })
        }
    },
    computed: {
        imageLink(): string {
            return `/images/${this.routeData.image}.png`
        }
    }
});
