
import BaseDialog, {sharedDialogMixin} from "@/components/dialogs/base/BaseDialog.vue";
import {DialogAction, DialogName} from "@/utils/Constants";
import {defineComponent, PropType} from "vue";
import {translationMixin} from "@/mixins/TranslationMixin";
import ConfigModule from "@/modules/ConfigModule";
import NormalButton from "@/components/buttons/NormalButton.vue";

export default defineComponent({
    mixins: [translationMixin, sharedDialogMixin],
    components: {
        NormalButton,
        BaseDialog
    },
    props: {
        callback: {type: Function as PropType<(action: DialogAction, data?: any) => void>, required: true}
    },
    data() {
        const data = ConfigModule.getDialogByName(DialogName.GAME_OVER);
        return {
            copy: data.copy,
            image: data.image,
            confirmImage: data.confirmImage,
            buttons: data.buttons
        }
    },
    methods: {
        selectOption(data: any) {
            this.sharedData = data;
            this.confirmMode = true;
        }
    }
});
