
import {defineComponent, PropType} from "vue";
import {translationMixin} from "@/mixins/TranslationMixin";
import {IFormCheckbox, IFormCheckboxCopy} from "@/interfaces/form/IFormCheckbox";
import Helper from "@/utils/Helper";
import nativeModule from "@/modules/NativeModule";

export default defineComponent({
    mixins: [translationMixin],
    props: {
        settings: {type: Object as PropType<IFormCheckbox>, required: true},
        modelValue: Boolean
    },
    emits: ["update:modelValue"],
    data() {
        return {
            translatedCopy: this.getTranslatedCopy<IFormCheckboxCopy>(this.settings.copy),
            internalValue: this.modelValue,
            error: false,
            anchorUrl: "",
            anchorTarget: ""
        }
    },
    methods: {
        validate(): boolean {
            if(!this.settings.optional && !this.internalValue) {
                return this.update(true);
            }

            //Update value
            return this.update(false);
        },
        update(error: boolean): boolean {
            this.error = error;
            this.$emit("update:modelValue", !error ? this.internalValue : false);
            return !error;
        }
    },
    computed: {
        errorMode(): string {
            return this.error ? "error" : "";
        }
    },
    mounted() {
        this.internalValue = this.settings.defaultState || false;

        //Native handling
        const isNative = nativeModule.isNative();
        const baseUrl = `${Helper.getBaseUrl()}/${this.getTranslation(this.translatedCopy.link)}`;

        //Set terms target & url
        if(isNative) {
            this.anchorUrl = Helper.getWebViewUrl(baseUrl);
            this.anchorTarget = "_self";
        } else {
            this.anchorUrl = baseUrl;
            this.anchorTarget = "_blank";
        }
    }
});
