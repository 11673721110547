
import {defineComponent} from "vue";
import Game from "@/game/game";
import {getSaveData, setSaveData, stateMixin} from "@/mixins/StateMixin";

//Game reference
let game: Game;

//Definition
const PhaserGame = defineComponent({
    mixins: [stateMixin],
    props: {
        backgroundMode: Boolean,
        onBootEvent: Function,
        onLoadEvent: Function,
        onStartEvent: Function,
        onEndEvent: Function,
        onHaltEvent: Function,
        onScoreEvent: Function
    },
    data() {
        return {
            gameElement: document.querySelector("#game") as HTMLDivElement
        }
    },
    mounted() {
        this.gameElement.style.display = "block";

        //Create instance
        let firstLoad = false;
        if(!game) {
            game = new Game(this.gameElement.clientWidth, this.gameElement.clientHeight);
            firstLoad = true;
        }

        //Get & set game state data
        const saveData = getSaveData();
        game.setSaveData(saveData.game);

        //Set external events
        game.setEventListener("booted", this.onBootEvent);
        game.setEventListener("loaded", this.onLoadEvent);
        game.setEventListener("start", this.onStartEvent);
        game.setEventListener("end", this.onEndEvent);
        game.setEventListener("halt", this.onHaltEvent);
        game.setEventListener("score", this.onScoreEvent);

        //Set internal events
        game.setEventListener("save", (newData: any) => {
            saveData.game = newData;
            setSaveData(saveData);
        });

        //Handle background / game mode
        if(this.backgroundMode) {
            this.gameElement.classList.add("background");
        }
        else {
            this.gameElement.classList.remove("background");
        }
    },
    unmounted() {
        this.gameElement.style.display = "none";

        //Remove all events
        game.removeEventListener("booted");
        game.removeEventListener("loaded");
        game.removeEventListener("start");
        game.removeEventListener("end");
        game.removeEventListener("halt");
        game.removeEventListener("score");
        game.removeEventListener("save");
    },
    methods: {
        beginGame() {
            game.startScene("game");
        },
        beginLoad() {
            game.beginLoad();
        },
        hideLoad() {
            game.endLoad();
        },
        setBackgroundColor(color: number) {
            game.setBackgroundColor(color);
        }
    }
});

//Export
export default PhaserGame;
export type PhaserGameType = InstanceType<typeof PhaserGame>;

