import Phaser from "phaser";

export default class Background extends Phaser.Scene {

    constructor() {
        super({
            key: "background"
        });
    }

    create() {
        // this.tileSpritePattern = this.add.tileSprite(0, 0, this.cameras.main.width, this.cameras.main.height, 'pattern').setOrigin(0, 0);
        // this.tileSpritePattern.setAlpha(0.8);

        // Notify that game has booted
        this.game.events.emit("booted");
    }

    setColor(color) {
        this.cameras.main.setBackgroundColor(color ? color : 0xFFDE00);
    }

    playMusic() {
        this.stopMusic();

        //Create music instance & play
        this.music = this.sound.add("background-music");
        //this.music.play({loop: true, volume: 0.4});
    }

    stopMusic() {
        if(this.music) {
            this.music.destroy();
        }
    }

    update(time, delta) {
        // this.tileSpritePattern.tilePositionY -= 0.5;
        // this.tileSpritePattern.tilePositionX += 0.5;
    }
}