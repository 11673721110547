
import {defineComponent} from "vue";
import PhaserGame from "@/components/PhaserGame.vue";
import apiModule from "@/modules/APIModule";
import {DialogAction, PageName} from "@/utils/Constants";
import GameOverDialog from "@/components/dialogs/GameOverDialog.vue";

export default defineComponent({
    components: {GameOverDialog, PhaserGame},
    data() {
        return {
            scoreToken: "",
            showDialog: false,
            gameCallback: null as any,
            gameData: {} as {[key: string]: number}
        };
    },
    mounted() {
        this.$refs.phaser?.beginGame();
    },
    methods: {
        onBoot() {
            this.$refs.phaser?.beginLoad();
        },
        onLoad() {
            this.$refs.phaser?.hideLoad();
            this.$refs.phaser?.beginGame();
        },
        onScore(key: string) {
            if(!(key in this.gameData)) {
                this.gameData[key] = 1;
            } else {
                this.gameData[key]++;
            }
        },
        onStart(level: number = 1) {
            apiModule.beginGame(level).then(result => {
                this.scoreToken = result["token"];
            });
        },
        onEnd(data: any) {
            apiModule.endGame(this.scoreToken, data["score"] || 1, this.gameData).catch(e => console.error("failed to save score:", e));

            //Go to score page
            this.$router.replace({
                name: PageName.SCORE,
                params: {
                    data: JSON.stringify(data)
                }
            });
        },
        onHalt(callback: any) {
            this.gameCallback = callback;
            this.showDialog = true;
        },
        dialogCallback(action: DialogAction, data?: any) {
            if(action !== DialogAction.CONFIRM) {
                return this.runGameCallback(0);
            }

            //NOTE: Perform API/native calls here

            //Apply selection
            this.runGameCallback(data["value"]);
        },
        runGameCallback(value: number) {
            if(this.gameCallback) {
                this.gameCallback(value);
            }
            this.showDialog = false;
        }
    }
});
