
import { PageName } from "@/utils/Constants";
import { defineComponent } from "vue";
import ConfigModule from "@/modules/ConfigModule";
import apiModule from "@/modules/APIModule";
import { stateMixin } from "@/mixins/StateMixin";
import PhaserGame from "@/components/PhaserGame.vue";

// import "@lottiefiles/lottie-player";

export default defineComponent({
  mixins: [stateMixin],
  components: {
    PhaserGame,
  },
  data() {
    return {
      setupReady: false,
      phaserReady: false,
      signedIn: false,
    };
  },
  methods: {
    onBoot() {
      this.$refs.phaser?.beginLoad();
      this.$refs.phaser?.setBackgroundColor(0xf5dc27);

      this.finish();
    },
    onLoad() {
      this.phaserReady = true;
      this.finish();
    },
    finish() {
      if (!this.phaserReady) {
        return;
      }

      //Hide load
      this.$refs.phaser?.hideLoad();

      //Go to correct route
      if (!this.getElementShown(PageName.REGISTER)) {
        this.$router.replace(ConfigModule.getPageLink(PageName.REGISTER));
      } else {
        this.$router.replace(ConfigModule.getPageLink(PageName.WALKTHROUGH));
      }
    },
  },
});
