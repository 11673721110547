
import CustomHeader from "@/components/CustomHeader.vue";
import StoreItem from "@/components/lists/StoreItem.vue";
import LogoLoader from "@/components/loaders/LogoLoader.vue";
import {IStore} from "@/interfaces/api/IStore";
import {PageName} from "@/utils/Constants";
import {defineComponent} from "vue";
import {translationMixin} from "@/mixins/TranslationMixin";
import ConfigModule from "@/modules/ConfigModule";
import {IPageCopy} from "@/interfaces/IPage";
import BaseButton from "@/components/buttons/base/BaseButton.vue";

export default defineComponent({
    mixins: [translationMixin],
    components: {
        BaseButton,
        CustomHeader,
        LogoLoader,
        StoreItem
    },
    data() {
        const data = ConfigModule.getPageByName(PageName.STORE);
        return {
            PageName,
            translatedCopy: this.getTranslatedCopy<IPageCopy>(data.copy),
            items: [] as IStore[]
        }
    },
    async mounted() {
        //TODO: Add API call

        //Fake wait
        //await Helper.sleep(2000);

        //Generate mock items
        const items: IStore[] = [];
        for(let i=0; i<20; i++) {
            items.push({
                id: (i+1),
                code: "123456",
                name: "20% korting",
                description: "Choco Superdeal",
                image: "product",
                cost: 10,
                requiredLevel: 1
            });
        }
        this.items = items;
    },
    methods: {
        openItem(item: IStore) {
            this.$router.replace({
                name: PageName.STORE_DETAIL,
                params: {
                    data: JSON.stringify(item)
                }
            });
        }
    },
    computed: {
        launchLink(): string {
            return ConfigModule.getPageLink(PageName.LAUNCH);
        }
    }
});
