
import CustomHeader from "@/components/CustomHeader.vue";
import CheckPrizeDialog from "@/components/dialogs/CheckPrizeDialog.vue";
import {PageName} from "@/utils/Constants";
import {defineComponent} from "vue";
import {translationMixin} from "@/mixins/TranslationMixin";
import ConfigModule from "@/modules/ConfigModule";
import {IPageCopy} from "@/interfaces/IPage";
import RouterButton from "@/components/buttons/RouterButton.vue";

interface LaunchPageCopy extends IPageCopy {
    endTitle?: string,
    endDescription?: string
}

export default defineComponent({
    mixins: [translationMixin],
    components: {
        CustomHeader,
        CheckPrizeDialog,
        RouterButton
    },
    data() {
        const data = ConfigModule.getPageByName(PageName.LAUNCH);

        return {
            translatedCopy: this.getTranslatedCopy<LaunchPageCopy>(data.copy)
        }
    },
    computed: {
        gameLink(): string {
            return ConfigModule.getPageLink(PageName.GAME);
        },
        storeLink(): string {
            return ConfigModule.getPageLink(PageName.STORE);
        },
        settingsLink(): string {
            return ConfigModule.getPageLink(PageName.SETTINGS);
        },
        ended(): boolean {
            const endTimestamp = ConfigModule.get().settings.endTimestamp;
            const currentTimestamp = Math.floor(Date.now() / 1000);
            return !!endTimestamp && currentTimestamp >= endTimestamp;
        }
    }
});
