
import CustomFooter from "@/components/CustomFooter.vue";
import CustomHeader from "@/components/CustomHeader.vue";
import CustomForm from "@/components/form/CustomForm.vue";
import {IFormPage, IPageCopy} from "@/interfaces/IPage";
import apiModule from "@/modules/APIModule";
import {LoginType, PageName} from "@/utils/Constants";
import {defineComponent} from "vue";
import {translationMixin} from "@/mixins/TranslationMixin";
import ConfigModule from "@/modules/ConfigModule";
import {setSaveData} from "@/mixins/StateMixin";
import BaseButton from "@/components/buttons/base/BaseButton.vue";

//Component
export default defineComponent({
    mixins: [translationMixin],
    components: {
        BaseButton,
        CustomForm,
        CustomFooter,
        CustomHeader
    },
    data() {
        const data = ConfigModule.getPageByName(PageName.LOGIN) as IFormPage;
        return {
            PageName,
            translatedCopy: this.getTranslatedCopy<IPageCopy>(data.copy),
            formContent: data.formContent
        }
    },
    methods: {
        async handleForm(data: any) {
            await apiModule.signInEmailUser(data["email"], data["password"]);

            //Get response
            const response = await apiModule.loginUser(LoginType.EMAIL);
            if(response["saveData"]) {
                await setSaveData(response["saveData"]);
            }

            //Go to next page
            await this.$router.replace(ConfigModule.getPageLink(PageName.WALKTHROUGH));
        }
    },
    computed: {
        registerLink(): string {
            return ConfigModule.getPageLink(PageName.REGISTER)
        }
    }
});
