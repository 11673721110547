
import {defineComponent} from "vue";

export default defineComponent({
    props: {
        fixed: Boolean
    },
    methods: {
        getImage() {
            if(this.isLarge) {
                return require("../assets/images/footer-large.png");
            }
            else {
                return require("../assets/images/footer-small.png");
            }
        }
    },
    computed: {
        isFixed(): string {
            return this.fixed ? "fixed" : "";
        },
        isLarge(): boolean {
            return "default" in this.$slots;
        }
    }
});
