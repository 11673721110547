/**
 * Utility class to watch for variable changes
 * T is the type of variable we're watching
 */
export default class Watcher<T> {

    private value?: T;
    private listener?: (newValue: T) => void;
    private listenOnce: boolean = false;

    /**
     * Constructor, prepares class with initial value
     * @param initialValue the initial value (optional, is just 'undefined' by default)
     */
    constructor(initialValue?: T) {
        this.value = initialValue;
    }

    /**
     * Update function, use this to update the value - event listener will be called (if set)
     * @param newValue the new value
     */
    updateValue(newValue: T) {
        this.value = newValue;

        //Notify listeners
        if(this.listener) {
            this.listener(newValue);

            //Remove listener, if set to once
            if(this.listenOnce) {
                this.clearListener();
            }
        }
    }

    /**
     * Get the current value
     * @returns value the current value
     */
    getValue(): T | undefined {
        return this.value;
    }

    /**
     * Set an event listener
     * @param listener the listener callback function
     * @param once set this to true, if you want your callback to trigger only once
     */
    setListener(listener: (newValue: T) => void, once: boolean = false) {
        this.listener = listener;
        this.listenOnce = once;
    }

    /**
     * Remove the current event listener
     */
    clearListener() {
        this.listener = undefined;
        this.listenOnce = false;
    }
}