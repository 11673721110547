
import {defineComponent} from "vue";

//Mixin with shared elements between base and extended buttons
export const sharedButtonMixin = defineComponent({
    props: {
        image: String
    },
    computed: {
        imageSrc(): any {
            return require(`@/assets/images/${this.image}`);
        }
    }
});

//Base definition
export default defineComponent({
    mixins: [sharedButtonMixin],
    props: {
        fullWidth: Boolean,
        playSound: Boolean,
        overrideSound: String
    },
    data() {
        return {
            audioInstance: null as HTMLAudioElement | null
        }
    },
    mounted() {
        if(this.playSound) {
            const resource = this.overrideSound ? this.overrideSound : "sounds/button.mp3";
            this.audioInstance = new Audio(resource);
        }
    },
    unmounted() {
        this.audioInstance?.remove();
    },
    methods: {
        onClick(): void {
            if(this.audioInstance) {
                this.audioInstance.currentTime = 0;
                this.audioInstance.play();
            }
        }
    },
    computed: {
        isFullWidth(): string {
            return this.fullWidth ? "full-width" : "";
        }
    }
});
