
import {IStore} from "@/interfaces/api/IStore";
import {defineComponent, PropType} from "vue";

export default defineComponent({
    props: {
        item: {type: Object as PropType<IStore>, required: true}
    },
    methods: {
        isLocked() {
            const level = 1;
            return level < this.item.requiredLevel ? "show" : "";
        }
    },
    computed: {
        imageLink(): string {
            return `/images/${this.item.image}.png`
        }
    }
});
