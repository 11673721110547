
import CustomHeader from "@/components/CustomHeader.vue";
import {PageName} from "@/utils/Constants";
import Helper from "@/utils/Helper";
import {defineComponent} from "vue";
import {ITranslation} from "@/interfaces/IConfig";
import {IPageCopy} from "@/interfaces/IPage";
import ConfigModule from "@/modules/ConfigModule";
import {translationMixin} from "@/mixins/TranslationMixin";
import NormalButton from "@/components/buttons/NormalButton.vue";

interface SettingsPageCopy extends IPageCopy {
    walkthroughTitle: ITranslation | string,
    walkthroughButton: ITranslation | string,
    termsTitle: ITranslation | string,
    termsButton: ITranslation | string
}

export default defineComponent({
    mixins: [translationMixin],
    components: {
        NormalButton,
        CustomHeader
    },
    data() {
        const data = ConfigModule.getPageByName(PageName.SETTINGS);
        return {
            PageName,
            translatedCopy: this.getTranslatedCopy<SettingsPageCopy>(data.copy)
        }
    },
    methods: {
        openWalkthrough() {
            this.$router.replace({
                name: PageName.WALKTHROUGH,
                query: {
                    skipLast: 1
                }
            });
        }
    },
    computed: {
        termsUrl(): string {
            return Helper.getTermsUrl();
        }
    }
});
