
import CustomFooter from "@/components/CustomFooter.vue";
import CustomHeader from "@/components/CustomHeader.vue";
import highscoreModule from "@/modules/HighscoreModule";
import {PageName} from "@/utils/Constants";
import {defineComponent, PropType} from "vue";
import {translationMixin} from "@/mixins/TranslationMixin";
import ConfigModule from "@/modules/ConfigModule";
import {IPageCopy} from "@/interfaces/IPage";
import RouterButton from "@/components/buttons/RouterButton.vue";

interface ScoreData {
    score: number,
    level: number
}

export default defineComponent({
    components: {RouterButton, CustomFooter, CustomHeader},
    mixins: [translationMixin],
    props: {
        routeData: {type: Object as PropType<ScoreData>, required: true}
    },
    data() {
        const data = ConfigModule.getPageByName(PageName.SCORE);

        //Update highscore module
        highscoreModule.store(this.routeData.level, this.routeData.score);

        //Return data
        return {
            translatedCopy: this.getTranslatedCopy<IPageCopy>(data.copy),
            highScore: highscoreModule.getHighest(this.routeData.level),
            highScoreDaily: highscoreModule.getHighestDaily(this.routeData.level),
            score: (this.routeData.score / 1000).toFixed(2),
            win: this.routeData.level,
        }
    },
    computed: {
        gameLink(): string {
            return ConfigModule.getPageLink(PageName.GAME);
        },
        launchLink(): string {
            return ConfigModule.getPageLink(PageName.LAUNCH);
        }
    }
});
