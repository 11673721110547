export default class Scaling {

    //Base values
    static GAME_BASE_WIDTH = 375;
    static GAME_BASE_HEIGHT = 667;

    //Calculated sizes/ratios
    static GAME_WIDTH = Scaling.GAME_BASE_WIDTH;
    static GAME_HEIGHT = Scaling.GAME_BASE_HEIGHT;
    static GAME_DIFF_RATIO = 1;

    //DPR
    static DPR = this.getDevicePixelRatio();

    //Helpers
    static updateResolutionRatio(parentWidth, parentHeight) {
        const diff = Math.abs((parentWidth / parentHeight) - (this.GAME_BASE_WIDTH / this.GAME_BASE_HEIGHT));

        //Update width/height if ratio offset too large
        if (diff > 0.001) {
            this.GAME_WIDTH = parentWidth;
            this.GAME_HEIGHT = parentHeight;
        }

        //Update diff ratio
        const diffRatio = parentHeight / this.GAME_BASE_HEIGHT;
        if(diffRatio > 1) {
            this.GAME_DIFF_RATIO = diffRatio;
        }
    }

    static getDevicePixelRatio() {
        const params = new URLSearchParams(window.location.search);
        const forceDpr = params.get("dpr");
        if (forceDpr) {
            return Math.floor(parseInt(forceDpr))
        }
        return Math.floor(Math.min(window.devicePixelRatio, 3));
    }

    static ImagePath(path, extension) {
        const filePath = `${path}@${this.DPR}x`
        return `${filePath}.${extension}`;
    }
}
