
import CustomCheckbox from "@/components/form/CustomCheckbox.vue";
import CustomDropdown from "@/components/form/CustomDropdown.vue";
import CustomInput from "@/components/form/CustomInput.vue";
import {IFormBase} from "@/interfaces/form/IFormBase";
import {defineComponent, PropType} from "vue";
import {translationMixin} from "@/mixins/TranslationMixin";
import BaseButton from "@/components/buttons/base/BaseButton.vue";

export default defineComponent({
    mixins: [translationMixin],
    components: {
        BaseButton,
        CustomCheckbox,
        CustomDropdown,
        CustomInput
    },
    props: {
        formContent: {type: Object as PropType<IFormBase[]>, required: true},
        buttonText: {type: String, required: true}
    },
    emits: ["submit"],
    data() {
        const formList = this.formContent.map(item => {
            item.value = null; //Set default value
            return item;
        });

        return {
            formList: formList,
            formRefs: [] as any[],
            translatedButtonText: this.getGlobalTranslation(this.buttonText)
        }
    },
    methods: {
        getComponentType(type: string) {
            switch(type) {
                case "checkbox":
                    return "custom-checkbox";
                case "dropdown":
                    return "custom-dropdown";
                default:
                    return "custom-input";
            }
        },
        process(e: Event) {
            e.preventDefault();

            //Trigger explicit validation
            let valid = true;
            for(const ref of this.formRefs) {
                const result = ref.validate();
                if(valid && !result) {
                    valid = result;
                }
            }

            //Block further actions if invalid
            if(!valid) {
                return;
            }

            //Map values & output
            const output: any = {};
            for(const item of this.formList) {
                output[item.name] = item.value;
            }
            this.$emit("submit", output);
        }
    }
});
